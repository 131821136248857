/* tslint:disable */
/* eslint-disable */
/**
 * MrGreener
 * Loyalty program for green initiatives
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Internal organization identifier
     * @type {number}
     * @memberof Organization
     */
    'organization_id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'registered_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'site_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'contact_email'?: string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'registered_on': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'points': number;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'promotion_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'picture_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'description_short': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'description_long': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'reward_points': number;
    /**
     * Internal organization id
     * @type {number}
     * @memberof Promotion
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'company_avatar_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'link_to_store': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'where_to_get'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Promotion
     */
    'verified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Promotion
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface PromotionVoucher
 */
export interface PromotionVoucher {
    /**
     * 
     * @type {string}
     * @memberof PromotionVoucher
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof PromotionVoucher
     */
    'rewardPoints'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionVoucher
     */
    'issuedOn'?: string;
}
/**
 * 
 * @export
 * @interface Reward
 */
export interface Reward {
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'name': string;
    /**
     * Internal reward identifier
     * @type {number}
     * @memberof Reward
     */
    'reward_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'picture_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'description_short': string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'description_long': string;
    /**
     * 
     * @type {number}
     * @memberof Reward
     */
    'price_points': number;
    /**
     * 
     * @type {number}
     * @memberof Reward
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'company_avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Reward
     */
    'verified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Reward
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface RewardVoucher
 */
export interface RewardVoucher {

    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RewardVoucher
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof RewardVoucher
     */
    'issuedOn': string;
}
/**
 * 
 * @export
 * @interface VoucherRequest
 */
export interface VoucherRequest {
    /**
     * 
     * @type {number}
     * @memberof VoucherRequest
     */
    'rewardPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherRequest
     */
    'promotion_id': number;
}

/**
 * CustomerAPIApi - axios parameter creator
 * @export
 */
export const CustomerAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Attempts to buy a reward voucher
         * @param {number} rewardId Internal reward id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyRewardRewardIdPost: async (rewardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('buyRewardRewardIdPost', 'rewardId', rewardId)
            const localVarPath = `/buyReward/{reward_id}`
                .replace(`{${"reward_id"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets my profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/getMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all verified organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check user
         * @param {string} username The username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilesUsernameGet: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('profilesUsernameGet', 'username', username)
            const localVarPath = `/profiles/{username}/`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all active&verified promotions
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsAllGet: async (orgId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('promotionsAllGet', 'orgId', orgId)
            const localVarPath = `/promotions/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['org_id'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetGet: async (promotionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsGetGet', 'promotionId', promotionId)
            const localVarPath = `/promotions/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promotionId !== undefined) {
                localVarQueryParameter['promotion_id'] = promotionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code Code for promotion voucher redemption
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemVoucherGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('redeemVoucherGet', 'code', code)
            const localVarPath = `/redeemVoucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all claimed reward voucher of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardVouchersMyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewardVouchers/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all active&verified rewards of an organization
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsAllGet: async (orgId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('rewardsAllGet', 'orgId', orgId)
            const localVarPath = `/rewards/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['org_id'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} rewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGetGet: async (rewardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('rewardsGetGet', 'rewardId', rewardId)
            const localVarPath = `/rewards/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rewardId !== undefined) {
                localVarQueryParameter['reward_id'] = rewardId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAPIApi - functional programming interface
 * @export
 */
export const CustomerAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Attempts to buy a reward voucher
         * @param {number} rewardId Internal reward id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyRewardRewardIdPost(rewardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyRewardRewardIdPost(rewardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets my profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all verified organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check user
         * @param {string} username The username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilesUsernameGet(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilesUsernameGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all active&verified promotions
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsAllGet(orgId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promotion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsAllGet(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsGetGet(promotionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsGetGet(promotionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code Code for promotion voucher redemption
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemVoucherGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemVoucherGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all claimed reward voucher of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardVouchersMyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardVoucher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardVouchersMyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists all active&verified rewards of an organization
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsAllGet(orgId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reward>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsAllGet(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} rewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsGetGet(rewardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reward>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsGetGet(rewardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAPIApi - factory interface
 * @export
 */
export const CustomerAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAPIApiFp(configuration)
    return {
        /**
         * Attempts to buy a reward voucher
         * @param {number} rewardId Internal reward id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyRewardRewardIdPost(rewardId: number, options?: any): AxiosPromise<RewardVoucher> {
            return localVarFp.buyRewardRewardIdPost(rewardId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets my profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeGet(options?: any): AxiosPromise<Profile> {
            return localVarFp.getMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all verified organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsAllGet(options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.organizationsAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check user
         * @param {string} username The username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilesUsernameGet(username: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.profilesUsernameGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all active&verified promotions
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsAllGet(orgId: number, options?: any): AxiosPromise<Array<Promotion>> {
            return localVarFp.promotionsAllGet(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetGet(promotionId: number, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionsGetGet(promotionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code Code for promotion voucher redemption
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemVoucherGet(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.redeemVoucherGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all claimed reward voucher of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardVouchersMyGet(options?: any): AxiosPromise<Array<RewardVoucher>> {
            return localVarFp.rewardVouchersMyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all active&verified rewards of an organization
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsAllGet(orgId: number, options?: any): AxiosPromise<Array<Reward>> {
            return localVarFp.rewardsAllGet(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} rewardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGetGet(rewardId: number, options?: any): AxiosPromise<Reward> {
            return localVarFp.rewardsGetGet(rewardId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAPIApi - object-oriented interface
 * @export
 * @class CustomerAPIApi
 * @extends {BaseAPI}
 */
export class CustomerAPIApi extends BaseAPI {
    /**
     * Attempts to buy a reward voucher
     * @param {number} rewardId Internal reward id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public buyRewardRewardIdPost(rewardId: number, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).buyRewardRewardIdPost(rewardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets my profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public getMeGet(options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).getMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all verified organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public organizationsAllGet(options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).organizationsAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check user
     * @param {string} username The username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public profilesUsernameGet(username: string, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).profilesUsernameGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all active&verified promotions
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public promotionsAllGet(orgId: number, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).promotionsAllGet(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public promotionsGetGet(promotionId: number, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).promotionsGetGet(promotionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code Code for promotion voucher redemption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public redeemVoucherGet(code: string, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).redeemVoucherGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all claimed reward voucher of the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public rewardVouchersMyGet(options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).rewardVouchersMyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists all active&verified rewards of an organization
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public rewardsAllGet(orgId: number, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).rewardsAllGet(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} rewardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAPIApi
     */
    public rewardsGetGet(rewardId: number, options?: AxiosRequestConfig) {
        return CustomerAPIApiFp(this.configuration).rewardsGetGet(rewardId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: any): AxiosPromise<string> {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Check status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgAPIApi - axios parameter creator
 * @export
 */
export const OrgAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds new promotion
         * @param {Promotion} [promotion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsAddPost: async (promotion?: Promotion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all my promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsMyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds new reward
         * @param {Reward} [reward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsAddPost: async (reward?: Reward, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reward, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all my rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsMyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgAPIApi - functional programming interface
 * @export
 */
export const OrgAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds new promotion
         * @param {Promotion} [promotion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsAddPost(promotion?: Promotion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsAddPost(promotion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all my promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsMyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promotion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsMyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds new reward
         * @param {Reward} [reward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsAddPost(reward?: Reward, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsAddPost(reward, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all my rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsMyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reward>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsMyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgAPIApi - factory interface
 * @export
 */
export const OrgAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgAPIApiFp(configuration)
    return {
        /**
         * Adds new promotion
         * @param {Promotion} [promotion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsAddPost(promotion?: Promotion, options?: any): AxiosPromise<void> {
            return localVarFp.promotionsAddPost(promotion, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all my promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsMyGet(options?: any): AxiosPromise<Array<Promotion>> {
            return localVarFp.promotionsMyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Adds new reward
         * @param {Reward} [reward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsAddPost(reward?: Reward, options?: any): AxiosPromise<void> {
            return localVarFp.rewardsAddPost(reward, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all my rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsMyGet(options?: any): AxiosPromise<Array<Reward>> {
            return localVarFp.rewardsMyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgAPIApi - object-oriented interface
 * @export
 * @class OrgAPIApi
 * @extends {BaseAPI}
 */
export class OrgAPIApi extends BaseAPI {
    /**
     * Adds new promotion
     * @param {Promotion} [promotion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgAPIApi
     */
    public promotionsAddPost(promotion?: Promotion, options?: AxiosRequestConfig) {
        return OrgAPIApiFp(this.configuration).promotionsAddPost(promotion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all my promotions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgAPIApi
     */
    public promotionsMyGet(options?: AxiosRequestConfig) {
        return OrgAPIApiFp(this.configuration).promotionsMyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds new reward
     * @param {Reward} [reward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgAPIApi
     */
    public rewardsAddPost(reward?: Reward, options?: AxiosRequestConfig) {
        return OrgAPIApiFp(this.configuration).rewardsAddPost(reward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all my rewards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgAPIApi
     */
    public rewardsMyGet(options?: AxiosRequestConfig) {
        return OrgAPIApiFp(this.configuration).rewardsMyGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseAPIApi - axios parameter creator
 * @export
 */
export const PurchaseAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Buys a new promotion voucher for given promotion
         * @param {VoucherRequest} [voucherRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyVoucherPost: async (voucherRequest?: VoucherRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/buyVoucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-KEY", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(voucherRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseAPIApi - functional programming interface
 * @export
 */
export const PurchaseAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Buys a new promotion voucher for given promotion
         * @param {VoucherRequest} [voucherRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyVoucherPost(voucherRequest?: VoucherRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyVoucherPost(voucherRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseAPIApi - factory interface
 * @export
 */
export const PurchaseAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseAPIApiFp(configuration)
    return {
        /**
         * Buys a new promotion voucher for given promotion
         * @param {VoucherRequest} [voucherRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyVoucherPost(voucherRequest?: VoucherRequest, options?: any): AxiosPromise<PromotionVoucher> {
            return localVarFp.buyVoucherPost(voucherRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseAPIApi - object-oriented interface
 * @export
 * @class PurchaseAPIApi
 * @extends {BaseAPI}
 */
export class PurchaseAPIApi extends BaseAPI {
    /**
     * Buys a new promotion voucher for given promotion
     * @param {VoucherRequest} [voucherRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseAPIApi
     */
    public buyVoucherPost(voucherRequest?: VoucherRequest, options?: AxiosRequestConfig) {
        return PurchaseAPIApiFp(this.configuration).buyVoucherPost(voucherRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


