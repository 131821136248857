import React, { FC } from 'react';


interface SpendPageProps {}

const SpendPage: FC<SpendPageProps> = () => (
  <div>
    SpendPage Component
  </div>
);

export default SpendPage;
